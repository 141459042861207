import axios from 'axios';

// const BaseURL = 'http://172.16.9.84:3355/'

const BaseURL = 'https://centerapi.well-healthcare.com/'

// const BaseURL = 'http://centerapi.well-healthcare.com:9022/'

// 创建第一个 axios 实例
// const axiosInstance1 = axios.create({
//     baseURL: BaseURL1,
//     // 其他配置...
// });

// 创建第二个 axios 实例
// const axiosCenter = axios.create({
//     baseURL: 'https://centerapi.well-healthcare.com/',
//     // 其他配置...
// });


// Set the base URL for axios
import {ElMessage, ElMessageBox} from 'element-plus'
import router from "@/router";
import store from "@/store";


import ReportApi from '@/api/modules/ReportApi.js';
import CompanyApi from '@/api/modules/CompanyApi'
import ChannelApi from '@/api/modules/ChannelApi'
import PublicApi from "@/api/modules/PublicApi"
import ProjectApi from "@/api/modules/ProjectApi";
import {getCurrentInstance} from "vue";

axios.defaults.baseURL = BaseURL;


const api = {
    ReportApi,
    CompanyApi,
    ChannelApi,
    PublicApi,
    ProjectApi,
}


// 响应拦截器：当后端将请求结果返回到前端组件之前，会被“响应拦截器”拦截下来
axios.interceptors.response.use((res) => {
    // 将 return 的数据返回给前端组件
    return res.data;
}, (err) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
        // console.log("Current Route Path:", router.currentRoute.value.path);
        if (router.currentRoute.value.path.startsWith("/mobile")) {
            ElMessageBox.alert('登录已过期，请重新登录', '警告', {
                confirmButtonText: '确定',
                callback: action => {
                    router.replace('/mobile/project/login');
                }
            });
        } else {
            ElMessageBox.alert('登录已过期，请重新登录', '警告', {
                confirmButtonText: '确定',
                callback: action => {
                    router.replace('/login');
                }
            });
        }
    }
    return Promise.reject(err);
});


export default api;