import axios from "axios"


const ProjectApi = {

    // --------------------------受检人-------------------------
    //新增受检人
    createPatient(data, headers) {
        return axios({
            url: '/api/report/report/patient/',
            method: 'POST',
            data,
            headers,
        })
    },

    //删除受检人
    deletePatient(id, headers) {
        return axios({
            url: "/api/report/report/patient/" + id + "/",
            method: 'DELETE',
            headers
        })
    },

    //更新受检人
    updatePatient(id, data, headers) {
        return axios({
            url: "/api/report/report/patient/" + id + "/",
            method: 'PUT',
            data,
            headers
        })
    },

    // 获取受检者列表
    getPatientList(params, headers) {
        return axios({
            url: '/api/report/report/patient/',
            method: 'GET',
            params,
            headers
        })
    },


    //根据id获取指定受检人
    retrievePatient(id, headers) {
        return axios({
            url: "/api/report/report/patient/" + id + "/",
            method: 'GET',
            headers
        })
    },

    // --------------------------项目-------------------------
    // 获取项目列表
    getProjectList(params) {
        return axios({
            url: '/api/report/report/project/',
            method: 'GET',
            params
        })
    },

    // --------------------------用户-------------------------
    // 用户登录
    userLogin(data) {
        return axios({
            url: '/api/report/report/user/login/',
            method: 'POST',
            data
        })
    },
    //用户创建
    createUser(data) {
        return axios({
            url: '/api/report/report/user/',
            method: 'POST',
            data
        })
    },

    //修改用户信息---手机号
    updateUser(id,data,headers){
        return axios({
            url: "/api/report/report/user/" + id + "/",
            method: 'PUT',
            data,
            headers
        })
    },

    //根据id获取指定用户
    retrieveUser(id, headers) {
        return axios({
            url: "/api/report/report/user/" + id + "/",
            method: 'GET',
            headers
        })
    },



    // --------------------------样本信息------------------------
    // 获取样本列表
    listSampleInfo(params, headers) {
        return axios({
            url: '/api/report/sample/code/',
            method: 'GET',
            params,
            headers
        })
    },
    //修改样本信息
    updateSampleInfo(id,data,headers){
        return axios({
            url: "/api/report/report/user/" + id + "/",
            method: 'PUT',
            data,
            headers
        })
    },


    // --------------------------样本绑定-------------------------
    // 绑定受检者和样本号（报告）
    bindSampleAndPatient(data, headers) {
        return axios({
            url: '/api/report/report/patient/and/sample/',
            method: 'POST',
            data,
            headers,
        })
    },

    // 获取受检者和样本号列表（报告）
    listSampleAndPatient(params, headers) {
        return axios({
            url: '/api/report/report/patient/and/sample/',
            method: 'GET',
            params,
            headers
        })
    },

    // 查看是否有CT报告
    listCtReport(params, headers){
        return axios({
            url: '/api/utils/questionnaire/',
            method: 'GET',
            params,
            headers
        })
    },


    // --------------------------微信自动登录-------------------------
    // 获取openid
    getWxGzhOpenid(params) {
        return axios({
            url: '/api/report/wx/gzh/openid/',
            method: 'GET',
            params,
        })
    },


    //
    // // 更新样本信息
    // sampleUpdate(data) {
    //     return axios({
    //         url: "/api/report/public/sample/",
    //         method: "PUT",
    //         data,
    //     })
    // },
    //
    //
    // // 增加签名记录
    // signatureCreate(data) {
    //     return axios({
    //         url: "/api/report/report/sample/",
    //         method: "POST",
    //         data,
    //         headers: {
    //             'Content-Type': 'multipart/form-data', // 确保设置正确的 Content-Type
    //         },
    //     })
    // },


}

export default ProjectApi;