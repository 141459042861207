import axios from "axios"

// 创建xios 实例,用不同的baseurl，对接不同的后端
// const axiosCenter = axios.create({
//     baseURL: 'https://centerapi.well-healthcare.com/',
//     // 其他配置...
// });


const PublicApi = {
    // 报告
    // list(params) {
    //     return axiosCenter({
    //         url: '/api/report/public/',
    //         method: 'GET',
    //         params
    //     })
    // },

    //查看样本信息
    sampleList(params) {
        return axios({
            url: '/api/report/public/sample/',
            method: 'GET',
            params
        })
    },

    // 更新样本信息
    sampleUpdate(data) {
        return axios({
            url: "/api/report/public/sample/",
            method: "PUT",
            data,
        })
    },


    // 增加签名记录
    signatureCreate(data) {
        return axios({
            url: "/api/report/report/sample/",
            method: "POST",
            data,
            headers: {
                'Content-Type': 'multipart/form-data', // 确保设置正确的 Content-Type
            },
        })
    },



}

export default PublicApi;