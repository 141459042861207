import api from "@/api"


export default {
    namespaced: true,
    state: {
        user_id: null,
        project: null,
        patient:null,
    },
    getters: {},
    mutations: {
        SET_USER_ID(state, payload) {
            state.user_id = payload;
        },
        SET_PROJECT(state, payload) {
            state.project = payload;
        },
        SET_PATIENT(state, payload) {
            state.patient = payload;
        },

    },
    actions: {
        // 获取新闻数据
        async getNewsAsync(context, params) {
            api.newsapi.list(params)
                .then(res => {
                    // 调用mutations方法

                    context.commit('SET_NEWS', res.data.results);
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    key: 'ProjectModule'  // 添加 key 属性
}

