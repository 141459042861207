<template>
    <!--  <nav>-->
    <!--    <router-link to="/">Home</router-link> |-->
    <!--    <router-link to="/about">About</router-link>-->
    <!--  </nav>-->
    <router-view/>
</template>

<script>
//禁止页面拖动


</script>

<style lang="scss">
@import '@/style/global.scss';

//微信浏览器下拉呈现白色的背景，不展示别的内容
//body:before {
//    width: 100%;
//    height: 100%;
//    content: ' ';
//    position: fixed;
//    z-index: -1;
//    top: 0;
//    left: 0;
//    background: #fff;
//}


// 自定义Vant的样式
.van-toast {
    width: 80% !important;
}


#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
