import Vue from 'vue'
import Vuex from 'vuex'
import newsModule from "@/store/modules/ProjectModule.js"
import TechArticleModule from "@/store/modules/TechArticleModule.js"
import ProjectModule from "@/store/modules/ProjectModule.js"

// 使用vuex-persistedstate默认存储到localStorage
import createPersistedState from "vuex-persistedstate"

export default new Vuex.Store({
    state: {
        // token: "",
        // user_id: "",
        loading: false,

    },
    getters: {},
    mutations: {
        // 设置loading
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
    },
    actions: {
        // 设置token
        async settokenAsync(context, payload) {
            context.commit('SET_TOKEN', payload);
        },

        // 获取验证码
        async getvalidcodeAsync(context, payload) {
            context.commit('SET_AUTHCODE', payload);
        },
    },
    modules: {
        newsModule,
        TechArticleModule,
        ProjectModule
    },
    // vuex持久化
    plugins: [createPersistedState()],

})
