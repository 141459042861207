import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"


// 自己的文件
import api from '@/api';


//vant全局
import vant from 'vant';
import 'vant/lib/index.css';


// element
import ElementPlus from 'element-plus'
// 引入中文语言包
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 根据环境变量判断是否引入 vConsole
if (process.env.NODE_ENV === 'development') {
  const VConsole = require('vconsole');
  const vConsole = new VConsole();
}

app.config.productionTip = false;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$api = api;
// app.config.globalProperties.$api = api;


app.use(vant)
// app.use(ElementPlus)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(store).use(router).mount('#app')


// 全局注册微信jsdk
import wx from 'weixin-js-sdk'
app.config.globalProperties.$wxsdk = wx
app.config.globalProperties.$wxurl = ''



// createApp(App).use(store).use(router).mount('#app')
