
import api from "@/api"



export default {
    namespaced: true,
    state: {
        articles: [],
    },
    getters: {
    },
    mutations: {
        SET_NEWS(state, payload) {
            state.articles = payload;
            // console.log(state.news)
        },

    },
    actions: {
        // 获取新闻数据
        async getTechArticleAsync(context, params) {
            api.techarticleapi.list(params)
                .then(res => {
                    // 调用mutations方法
                    
                    context.commit('SET_NEWS', res.data.results);
                })
                .catch(error => {
                    console.error(error);
                });
        },

    },
}
