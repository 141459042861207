import axios from "axios"

// 创建xios 实例,用不同的baseurl，对接不同的后端
const axiosCenter = axios.create({
    baseURL: 'https://centerapi.well-healthcare.com/',
    // 其他配置...
});


const ReportApi = {
    // 报告
    // list(params) {
    //     return axiosCenter({
    //         url: '/api/report/public/',
    //         method: 'GET',
    //         params
    //     })
    // },

    list(params) {
        return axios({
            url: '/api/report/public/',
            method: 'GET',
            params
        })
    },

    // 下载
    create(data) {
        return axios({
            url: "/api/report/public/",
            method: "POST",
            data,
            responseType: 'arraybuffer' // 添加此行,不然下载文件，会出错
        })
    },

    //下载
    download(url,download_token) {
        return axios({
            url: url+"?download_token="+download_token,
            method: "POST",
            responseType: 'arraybuffer' // 添加此行,不然下载文件，会出错
        })
    },

    //发送短信的登录验收码
    send_sms(data) {
        return axios({
            url: "/api/report/sms/",
            method: "POST",
            data,
        })
    },

}

export default ReportApi;