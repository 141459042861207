import axios from "axios"

// 公司
const CompanyApi = {
    create(data) {
        return axios({
            url: "/api/company/",
            method: "POST",
            data
        })
    },
    delete(id) {
        return axios({
            url: "/api/company/" + id + "/",
            method: 'DELETE',
        })
    },
    update(id, data) {
        return axios({
            url: "/api/company/" + id + "/",
            method: 'PUT',
            data
        })
    },
    list(params) {
        return axios({
            url: '/api/company/',
            method: 'GET',
            params
        })
    },
    retrieve(id, params) {
        return axios({
            url: "/api/company/" + id + "/",
            method: 'GET',
            params
        })
    },
}

export default CompanyApi;