import axios from "axios"

const ChannelApi = {

    // 渠道登录
    login(data) {
        return axios({
            url: "/api/report/channel/account/login/",
            method: "POST",
            data,
        })
    },

    //获取登录用户信息
    getLoginInfo(params, headers) {
        return axios({
            url: '/api/report/channel/account/login/',
            method: 'GET',
            params,
            headers
        })
    },

    // 重置渠道密码
    resetLoginInfo(data) {
        return axios({
            url: '/api/report/channel/account/login/',
            method: 'PUT',
            data,
        })
    },

    //获取lims的订单数据
    getLimsOrder(params, headers) {
        return axios({
            url: "/api/report/lims/order/",
            method: "GET",
            params,
            headers
        })
    },

    //修改lims的订单数据
    updateLimsOrder(data, headers) {
        return axios({
            url: "/api/report/lims/order/",
            method: "PUT",
            data,
            headers
        })
    },


    //导出渠道订单数据
    downloadLimsOrder(params, headers) {
        return axios({
            url: "/api/report/lims/order/excel/",
            method: "GET",
            params,
            headers,
            responseType: 'arraybuffer',  // 在下载文件时，responseType: 'arraybuffer' 会告诉浏览器将响应以二进制数组的形式处理，而不是默认的 JSON 格式。
        })
    },

    //导入渠道订单数据
    uploadOrder(data, headers) {
        return axios({
            url: "/api/report/lims/order/excel/",
            method: "PUT",
            data,
            headers,
        })
    },
}


export default ChannelApi;