import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {ElMessage, ElMessageBox} from 'element-plus'

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: "/mobile/report",
        // component: HomeView
    },
    // pc端
    // {
    //     path: "/report",
    //     name: "report",
    //     component: () => import("@/views/pc/ReportView")
    // },


    // 移动端
    {
        path: "/mobile/report",
        name: "report",
        component: () => import("@/views/mobile/ReportView"),
        children: [
            {
                path: "/preview/:pdfData",
                name: "previewPdf",
                component: () => import("@/views/mobile/PreviewPdfView")
            },
        ]
    },
    {
        path: "/mobile/select/sample",
        name: "sampleList",
        component: () => import("@/views/mobile/SampleListView")
    },
    {
        path: "/mobile/sample",
        name: "sample",
        component: () => import("@/views/mobile/SampleView")
    },
    {
        path: "/mobile/demo",
        name: "demo",
        component: () => import("@/views/mobile/demo")
    },
    {
        path: "/mobile/project/login",
        name: "project_login",
        component: () => import("@/views/mobile/project/LoginView")
    },
    {
        path: "/mobile/project/home",
        name: "project_home",
        component: () => import("@/views/mobile/project/HomeView")
    },
    {
        path: "/mobile/project/wxLogin",
        name: "project_wx_login",
        component: () => import("@/views/mobile/project/WxGzhLoginView")
    },
    {
        path: "/mobile/project/list",
        name: "project",
        component: () => import("@/views/mobile/project/ProjectListView")
    },
    {
        path: "/mobile/project/myself",
        name: "myself",
        component: () => import("@/views/mobile/project/MyselfView")
    },
    {
        path: "/mobile/project/patient/list",
        name: "patient_list",
        component: () => import("@/views/mobile/project/PatientListView")
    },
    {
        path: "/mobile/project/patient/add",
        name: "patient_add",
        component: () => import("@/views/mobile/project/PatientAddView")
    },
    {
        path: "/mobile/project/patient/edit/:id",
        name: "patient_edit",
        component: () => import("@/views/mobile/project/PatientEditView")
    },
    {
        path: "/mobile/project/barcode",
        name: "bind_barcode",
        component: () => import("@/views/mobile/project/BindBarcodeView")
    },
    {
        path: "/mobile/project/myself/info",
        name: "myself_info",
        component: () => import("@/views/mobile/project/MyselfInfoView")
    },
    {
        path: "/mobile/project/myself/project",
        name: "myself_project",
        component: () => import("@/views/mobile/project/MyselfProjectView")
    },
    {
        path: "/mobile/project/myself/phone",
        name: "myself_phone",
        component: () => import("@/views/mobile/project/MyselfResetPhoneView")
    },
    {
        path: "/mobile/project/myself/nodata",
        name: "nodata",
        component: () => import("@/views/mobile/project/NoDataView")
    },


    // 后台管理
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/cms/LoginView")
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/cms/RegisterView")
    },
    {
        path: "/forget",
        name: "forget",
        component: () => import("@/views/cms/ForgetView")
    },

    {
        path: "/cms",
        name: "cms",
        redirect: "/cms/index",
        component: () => import("../views/cms/CmsView"),
        children: [
            {
                path: '/cms/index',
                component: () => import("../views/cms/IndexView"),
            },
            {
                path: '/cms/sample',
                component: () => import("../views/cms/SampleView"),
            },
            {
                path: '/cms/report',
                component: () => import("../views/cms/ReportView"),
            },
            {
                path: '/cms/user',
                component: () => import("../views/cms/UserView"),
            },
            {
                path: '/cms/role',
                component: () => import("../views/cms/RoleView"),
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


//无法判定token是否失效 所以用axios拦截器实现该功能
// 全局导航守卫:判断进入后台的路由需要token鉴权。
// router.beforeEach((to, from, next) => {
//     if (to.path.includes("/cms")) {
//         // 判断用户的登录状态
//         if (localStorage.token) {
//             // 验证token
//             next();
//         } else {
//             ElMessageBox.alert('未登录', '警告', {
//                 confirmButtonText: '确定',
//                 callback: action => {
//                     next('/login');
//                 }
//             });
//         }
//     } else {
//         next();
//     }
// })

export default router
